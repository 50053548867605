@import "@Static/css/common";

.container {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    color: rgb(var(--template-block-text-host-theme, 34, 34, 34)) !important;
    background-color: rgb(
        var(--template-block-background-host-theme, 255, 255, 255)
    );
    margin: 0 0.5rem 1.1rem 0.5rem;
    box-shadow: 0px 5px 8px #00000014;
    width: 360px;
    height: 275px;
}

.blogButton {
    padding: 0;
    text-align: left;
    text-decoration: none;
    font-weight: 400;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.image {
    width: 100%;
    height: 118.662px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px 5px 0 0;
}

.infoContainer {
    flex-grow: 1;
    margin: 1rem 1rem 0 1rem;
    display: flex;
    flex-direction: column;
}

.fontWeightBold {
    font-weight: 700;
}

.title {
    font-size: 1rem;
    margin-bottom: 0.4rem;
}

.timeInfo {
    display: flex;
    color: rgb(var(--template-block-text-host-theme, 34, 34, 34));
}

.timeInfoDot {
    margin: 0 0.4rem;
}

.footer {
    margin: 0 1rem 1rem 1rem;
}

.postStatsContainer {
    display: flex;
}

.postStatContainer {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0;
    margin-right: 0.7rem;
}

.statCount {
    margin-left: 0.3rem;
}
