/** To have the class be picked up automatically, name it according to the following convention:
*  [<modalType>]-[{Target Element}]
*/

.EventModal-modalContainer {
    height: 86vh;
    width: 90vw;
    max-width: 90vw !important;
    border-radius: 8px;
    transition: none;
}

.SocialShare-modalContainer {
    width: auto;
}

.GetInTouch-modalContainer {
    max-width: 90vw;
}
