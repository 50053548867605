@import "@Static/css/common";

.rectangleContainerWithCurvedProgressBar {
    &.container {
        position: relative;
        width: 100%;
        height: 0.5rem;
        background-color: #dfe1e5;
    }

    &.loader {
        position: absolute;
        height: 100%;

        &:not(.loadingComplete) {
            border-top-right-radius: 100000rem;
            border-bottom-right-radius: 100000rem;
        }

        &.controlled {
            transition: width ease-in-out 1s;
        }

        &.auto {
            width: 100%;
            border-radius: 0;
            animation: progress 4s linear;
            @keyframes progress {
                0% {
                    width: 0%;
                }
                25% {
                    width: 50%;
                }
                50% {
                    width: 75%;
                }
                75% {
                    width: 85%;
                }
                100% {
                    width: 100%;
                }
            }
        }

        background-color: black;
    }
}

.spinner {
    display: inline-block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 6px solid;

    animation: spinner 0.5s linear infinite;

    @keyframes spinner {
        0% {
            transform: rotate(0deg) scale(0.9);
            opacity: 0.3;
        }
        50% {
            transform: rotate(270deg);
            opacity: 0.9;
        }
        100% {
            transform: rotate(360deg) scale(0.9);
            opacity: 0.3;
        }
    }

    border-color: transparent rgba(255, 91, 0);
}
