.root {
    position: fixed;
    bottom: 60px;
    right: 24px;
    left: auto;
    z-index: 99999999999999;
}

.list {
    display: flex;
    flex-direction: column;
}

.toastContainer {
    margin: 8px 0 8px auto;
    width: fit-content;
    transition: 0.2s ease all;
    cursor: pointer;
}

.toastRoot {
    height: fit-content;
}

.iconStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 5px;
}
@media only screen and (max-width: 468px) {
    .root {
        width: 100%;
        right: unset !important;
        display: flex;
        justify-content: center;
    }
    .toastContainer {
        width: 100%;
        margin: 8px auto 8px auto;
    }
}

.labelStyle {
    white-space: initial !important;
    text-overflow: unset !important;
}

.default_style {
    height: auto !important;
    min-height: 44px !important;
    box-sizing: border-box !important;
    font-size: 16px !important;
    padding: 0px 12px !important;
    border-radius: 5px !important;
    padding: 5px !important;
    color: #ffffff !important;
    background-color: #20194d !important;
}

.success_outlined {
    color: #00b779 !important;
    border: 1.5px solid #00b779 !important;
    background-color: #e5fff7 !important;
}

.error_outlined {
    color: #bf2600 !important;
    border: 1.5px solid #bf2600 !important;
    background-color: #fbebea !important;
}

.warning_outlined {
    color: #ffab00 !important;
    border: 1.5px solid #ffab00 !important;
    background-color: #fff7e5 !important;
}

.info_outlined {
    color: #493ab1 !important;
    border: 1.5px solid #493ab1 !important;
    background-color: #eeecf9 !important;
}

.icon_success_default,
.icon_error_default,
.icon_info_default,
.icon_warning_default {
    fill: #ffffff !important;
}

.icon_success_outlined {
    fill: #00b779 !important;
}

.icon_error_outlined {
    fill: #bf2600 !important;
}

.icon_warning_outlined {
    fill: #ffab00 !important;
}

.icon_info_outlined {
    fill: #493ab1 !important;
}

.icon_success_default_container {
    background-color: #00b779 !important;
}

.icon_success_outlined_container {
    background-color: #e5fff7 !important;
}

.icon_error_default_container {
    background-color: #bf2600 !important;
}

.icon_error_outlined_container {
    background-color: #fbebea !important;
}

.icon_warning_default_container {
    background-color: #ffab00 !important;
}

.icon_warning_outlined_container {
    background-color: #fff7e5 !important;
}

.icon_info_default_container {
    background-color: #493ab1 !important;
}

.icon_info_outlined_container {
    background-color: #eeecf9 !important;
}
