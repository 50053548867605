@import "@Static/css/common";

.roundedCornersTextInput {
    border: 2px solid #dfe1e5;
    border-radius: 5px;
    font-size: 0.8rem;
    padding: 5px 10px;

    transition: border-color ease-in 0.15s;
    &:focus,
    &:hover {
        border-color: #bbb;
    }
}

.applyTheme {
    &:focus {
        @include colorCombinationXYZ(
            (
                "boc": (
                    "key": 1,
                    color: (
                        0,
                        0,
                        0,
                    ),
                ),
            )
        );
    }
}

.inputSuccess {
    border-color: green !important;
}

.inputError {
    border-color: red !important;
}

.editingDisabled {
    cursor: not-allowed;
}
