$inTime: 300ms;
$outTime: 300ms;

@keyframes fadeIn {
    from {
        opacity: 0;
        background-color: transparent;
    }
    to {
        opacity: 1;
        background-color: #000c;
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.root {
    &.open {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        max-width: 100%;
        max-height: 100%;
        z-index: 1000000000;
    }
}

.backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000c;
    backdrop-filter: blur(6px);
    z-index: -1;
    animation: fadeIn $inTime;
    transition: $inTime ease-in all;
    &.isClosing {
        transition: $outTime ease-in all;
        animation: fadeIn $outTime reverse;
        opacity: 0;
    }
}

.modalRoot {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: $inTime ease-in all;
    animation: slideIn $inTime;
    transform: translateY(0);

    &.isClosing {
        transition: $outTime ease-in all;
        animation: slideIn $outTime reverse;
        transform: translateY(-100px);
        opacity: 0;
    }
}

.disableAnimations {
    transition: none !important;
    animation: none !important;
}

.modalContainer {
    z-index: 1;
    background: white;
    border-radius: 8px;
    max-width: 60vw;
}

@media only screen and (max-width: 600px) {
    .modalContainer {
        max-width: 100vw;
    }
}
