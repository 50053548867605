@import "@Static/css/common";

.container {
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 70vh;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.6rem 0.8rem;
    border-bottom: 2px solid #dfe1e5;
}

.boldText {
    font-weight: 600;
}

.button {
    padding: 0;
    text-decoration: none;
    color: #2e384d;
    display: flex;
    align-items: center;
}

.closeButton {
    margin-left: 1rem;
}

.body {
    padding: 1rem;
    flex-grow: 1;
    overflow-y: auto;
    @extend .plainScrollbar;
}

.centerAlignedContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (min-width: 601px) {
    .container {
        max-width: fit-content;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        width: 100%;
    }
}
