@import "@Static/css/common";

.button {
    cursor: pointer;
}

.base {
    margin-right: 0.5rem;
    &:last-of-type {
        margin-right: 0;
    }
}

.base {
    background-color: black;
}

.basegrey {
    background-color: var(--faqcolor5, #cccccc80);
}

.base.active {
    opacity: 1;
}

.activered {
    background-color: var(--faqcolor1);
}

.horizontalBar {
    width: 1rem;
    height: 3px;
}
.dot {
    height: 15px;
    width: 15px;
    border-radius: 50px;
    transition: 0.2 ease all;
    &:hover {
        filter: brightness(1.4);
    }
}
.dotsquare {
    border-radius: 0px;
}
