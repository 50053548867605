.commentContainer {
    display: flex;
    align-items: flex-start;
}

.commentInput {
    flex-grow: 1;
}

.commentPostButtonContainer {
    display: flex;
    justify-content: flex-end;
}

.commenterIcon {
    margin-right: 0.5rem;
}

.commenterAlphabetIcon {
    margin-right: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 900;
}

.commentInfoContainer {
    width: 100%;
}

.commentTitle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.commentContent {
    padding-bottom: 0.4rem;
    border-bottom: 1px solid rgb(211, 211, 211);
    margin-bottom: 8px;
}

.sectionGap {
    margin-bottom: 0.4rem;
}

.largeSectionGap {
    margin-bottom: 0.6rem;
}

.boldText {
    font-weight: 700;
}

.loadMoreContainer {
    display: flex;
    justify-content: center;
}

.timestamp {
    font-size: 13px;
    opacity: 0.5;
    letter-spacing: -0.2px;
}

.replyContainer {
    padding: 10px 2px 0px;
}

.downarrow {
    margin-right: 0.5rem;
}

.replyContent {
    padding-bottom: 5px;
}

.likeIcon {
    margin: 0 0.4rem 0 0.5rem;
    font-size: 1rem;
}

.textComment {
    white-space: pre-wrap;
}

.examResponse {
    border: 1.5px solid lightgray;
    border-radius: 8px;
    padding: 8px 16px;

    strong {
        font-family: sans-serif;
    }
}
