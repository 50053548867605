@import "@Static/css/common";

$primaryColor: getColorValue(1, (0, 0, 0));
$fadedPrimaryColor: getColorValue(1, (0, 0, 0), 0.2);
$bgColor: getColorValue(2, (255, 255, 255));
$fadedBgColor: getColorValue(2, (255, 255, 255), 0.2);
$textColor: getColorValue(3, (0, 0, 0));

.template {
    &.growth {
        .keyword {
            color: $bgColor;
            &:hover {
                background-color: $fadedBgColor;
            }
        }
    }
}

.keywordsContainer {
    margin: 0 0 1.5rem 0;
    padding: 0 0 0.5rem 0;
    max-width: 100%;
    overflow-x: auto;
    display: flex;
    @extend .themedScrollbar;
    @extend .template;
}

.keyword {
    margin: 0 1rem 0 0;
    padding: 5px 20px;
    background-color: transparent;
    cursor: pointer;
    white-space: nowrap;
    transition: 0.15s ease all;
    border-radius: 8px;
    border: 2px solid;
    border-color: transparent;
    color: $primaryColor;
    &:hover {
        background-color: $fadedPrimaryColor;
    }

    &:last-of-type {
        margin: 0;
    }
}

.selectedKeyword {
    @include colorCombinationXYZ(
        (
            "boc": (
                "color": (
                    223,
                    99,
                    93,
                ),
                "key": 1,
            ),
        )
    );
    border: 2px solid;
}
