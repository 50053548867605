.container {
    background-color: white;
    position: relative;
}

.popupRoot {
    position: fixed;

    // in mobile devices, the bottom search bar has "auto-hide"
    // and messes up with the visibility of bottom part of popup
    bottom: 0;

    left: 0;
    z-index: 30;
    width: 100vw;
    height: 100vh;
    backdrop-filter: brightness(0.5) blur(2px);
}

.header {
    display: flex;
    justify-content: center;
    padding: 0.6rem 0.8rem;
    border-bottom: 2px solid #dfe1e5;
}

.button {
    padding: 0;
    text-decoration: none;
    color: #2e384d;
    display: flex;
    align-items: center;
}

.closeButton {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
}

.body {
    padding: 1rem;
}

.addressInfosContainer {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.addressInfoContainer {
    display: flex;
    align-items: center;
}

.meetLink {
    word-break: break-all;
    padding: 0;
}

.centerAlignedContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.orangeContainer {
    background-color: #ffefe3;
    border-radius: 5px;
}

.boldText {
    font-weight: 600;
}

.largeText {
    font-size: 0.7rem;
}

.smallText {
    font-size: 0.6rem;
}

.startsOnContainer {
    padding: 0.5rem 2rem;
    margin: 1rem 0;
}

.timeLeft {
    padding: 0.1rem 0.5rem;
}

.orangeText {
    color: #ca3e47;
}

.joinViaZoomButton {
    text-decoration: underline;
    color: #0d72ee;
}

.joinMeetButton {
    padding: 8px 48px;
}

@media only screen and (min-width: 601px) {
    .container {
        max-width: 25rem;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        width: 100%;
    }
}
