.container {
    background-color: white;
}

.popupSection {
    padding: 0.8rem 1rem;
    color: #333;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #dfe1e5;
}

.plainButton {
    text-decoration: none;
    color: #2e384d;
}

.closeButton {
    padding: 0;
    display: flex;
    align-items: center;
}

.cancelButton {
    margin-left: 1rem;
}

.boldText {
    font-weight: 600;
}

.inputLabel {
    margin: 0 0 0.2rem 0;
}

.inputField {
    width: 100%;
}

.inputError {
    color: red;
    font-size: 0.7rem;
    height: 20px;
    text-align: right;
}

@media only screen and (min-width: 601px) {
    .container {
        max-width: 25rem;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        width: 100%;
    }

    .popupSection:last-of-type  {
        padding: 0.8rem 1rem 0 1rem;
    }
}
