@import "@Static/css/common";

.roundedCornersDropdownSelector {
    transition: border-color ease-in 0.3s;

    div[class$="control"] {
        box-shadow: 0 0 0 0 transparent;
        border: 2px solid #dfe1e5;
        border-radius: 5px;

        &:focus,
        &:hover {
            border-color: #ccc;
        }
    }

    div[class$="menu"] {
        margin: 0;
    }

    /*
    using following selector instead of `div[class$="MenuList"]`
    because the react-select package sometimes does not apply className "MenuList"
    */
    div[class$="menu"] > div {
        @extend .plainScrollbar;
    }

    span[class$="indicatorSeparator"] {
        display: none;
    }

    div[class$="placeholder"] {
        white-space: nowrap;
    }
}

.inputError {
    div[class$="control"] {
        border-color: red!important;
    }
}

.editingDisabled {
    opacity: 0.8;
    filter: grayscale(0.5);
    user-select: none;
    div[class$="control"] {
        cursor: not-allowed;
        pointer-events: auto;
    }
}

.labelContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.labelField {
    height: 28px;
}

.recommendedLabelField {
    line-height: 16px;
    font-size: 12px;
    background: #272522;
    color: white;
    padding: 5px 8px;
    border-radius: 3px;
    margin-right: 13px;
}

.customLabel {
    padding: 8px;
    background-color: white;
    color: #333;
    cursor: pointer;
  }
  
@media (max-width: 768px) {
}

