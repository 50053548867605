@import "@Static/css/common";

.root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;

    z-index: 1000000001;
    background: #000d;
    backdrop-filter: blur(24px);

    margin: auto;
    box-shadow: 0px 0.5px 2px 1px #3333;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    display: none;
    &.show {
        display: flex;
    }
}

.container {
    padding: 8px 20px 16px;
    border-radius: 4px;
    overflow: auto;
    background-color: #f7f7f7;
    max-width: 96vw;
}

.header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 8px;
    .title {
        font-size: 20px;
        padding: 8px 0 16px;
    }
    .close {
        position: absolute;
        right: 0;
        font-size: 22px;
        font-weight: 600;
        color: #555;
        transition: 0.15s ease all;
        cursor: pointer;
        &:hover {
            color: #222;
        }
    }
}

.payCta {
    margin: 28px auto 6px;
    padding: 12px 48px;
    width: 100%;
    @include colorCombinationXYZ(
        (
            "bgc": (
                "key": 1,
                "color": (
                    255,
                    91,
                    0,
                ),
            ),
        )
    );
    color: #fff;
    transition: 0.15s ease all;
    &:hover {
        filter: brightness(1.2);
    }
}
