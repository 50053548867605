@import "./loaders/index";
@import "@Static/css/common";

// Styling to be kept here:
// 1) HTML tags
// 2) Styles that won't be imported or extended elsewhere
// 3) Global overrides that WILL be used everywhere (like changing the button UI from skeuomorphic to flat)

// To apply theming to the testimonial carousel slider buttons
.carousel.slide {
    .carousel-indicators {
        li {
            @include colorCombinationXYZ(
                (
                    "bgc": (
                        "key": 1,
                    ),
                ),
                "schema1"
            );
            opacity: 0.4;
            &.active {
                opacity: 1;
            }
        }
    }
}

// To remove the highlight from elements when being used
* {
    &:focus,
    &:active,
    &:visited {
        outline: none !important;
        // Don't disable the box shadow, it can be in use
    }
}

body {
    height: 100%;
    overflow-y: overlay;
    &:first-child(.modal-backdrop.show) {
        opacity: 0.85 !important;
    }
    // Two classes for the same reason are being used so as to not inadvertedly remove the class set by another concern

    // For the case when the placeholder for SSR is shown
    &.noscroll-placeholder,
    // For the case when the modal is open
    &.noscroll-modal,
    // For the case when the stripe form is open
    &.noscroll-stripe {
        overflow: hidden;
    }
}

html {
    height: 100%;
    scroll-behavior: smooth;
}

// To kee the modal above the SPREAD WORD button in homely template
.modal-backdrop {
    z-index: 900001;
    .show {
        opacity: 1;
    }
}

.montserrat {
    font-family: "Montserrat", sans-serif;
}

input,
textarea {
    -webkit-appearance: none;
    border-radius: 0;
}

input[type="checkbox"] {
    appearance: checkbox;
}

.flexGrow {
    flex-grow: 1;
}

// TODO: Review Normalize.css
b,
strong {
    font-weight: 600;
}

.ql-font-serif {
    font-family: serif;
}

.ql-font-monospace {
    font-family: monospace;
}
