@import "@Static/css/common";
.loader8,
.loader8:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.loader8 {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2)!important;
    border-right: 1.1em solid;
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2)!important;
    border-left: 1.1em solid;
    @include colorCombinationXYZ(
        (
            "boc": (
                "key": 1,
            ),
        ),
        "schema3"
    );
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    60% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    60% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
