.root {
    position: absolute;

    // in mobile devices, the bottom search bar has "auto-hide"
    // and messes up with the visibility of bottom part of popup
    bottom: 0;

    left: 0;
    z-index: 800000;
    width: 100vw;
    height: 100vh;
    backdrop-filter: brightness(0.5) blur(2px);
}

.container {
    position: relative;
}

@media only screen and (min-width: 601px) {
    .container {
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        border-radius: 5px;
    }

    .opening {
        animation: slideUp ease-in-out;
        @keyframes slideUp {
            0% {
                opacity: 0;
                top: calc(50% + 1rem);
            }
            100% {
                opacity: 1;
                top: 50%;
            }
        }
    }

    .closing {
        animation: slideDown ease-in-out;
        @keyframes slideDown {
            0% {
                opacity: 1;
                top: 50%;
            }
            100% {
                opacity: 0;
                top: calc(50% + 1rem);
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .container {
        top: 100%;
        left: 50%;
        transform: translateY(-100%) translateX(-50%);
        width: 100%;
        border-radius: 5px 5px 0 0;
        padding-bottom: 3rem;
    }

    .opening {
        animation: slideUp ease-in-out;
        @keyframes slideUp {
            0% {
                opacity: 0;
                top: calc(100% + 1rem);
            }
            100% {
                opacity: 1;
                top: 100%;
            }
        }
    }

    .closing {
        animation: slideDown ease-in-out;
        @keyframes slideDown {
            0% {
                opacity: 1;
                top: 100%;
            }
            100% {
                opacity: 0;
                top: calc(100% + 1rem);
            }
        }
    }
}
