// TODO: Clean this out
// TODO: Clean this out [Really, please]
// TODO: Clean this out [Urgently]
@import "@Static/css/common";
@import "./bootstrapLikeStyles.scss";

$fontFamily: var(--primary-font), "Montserrat", sans-serif;

html,
body {
    padding: 0;
    margin: 0;
    font-family: $fontFamily;
    line-height: 1.6;
    font-size: 18px;
    position: relative;
    scroll-behavior: smooth;
}

button {
    font-family: $fontFamily;
    border: none;
}

input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    height: fit-content;
}
* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

hr {
    border-width: 0px;
    height: 1.5px;
    background-color: #ddd;
    border: 0.5px solid #ddd;
    margin: 16px 0;
}

input {
    border-style: solid;
}

@media only screen and (max-width: 768px) {
    // ios messes up input type date
    input[type="date"] {
        min-height: 39.0312px; // mobile
    }
}
@media only screen and (min-width: 769px) {
    input[type="date"] {
        // ios messes up input type date
        min-height: 44.7969px; // desktop
    }
}

// ios messes up input type checkbox
input[type="checkbox"] {
    min-height: 13px;
    min-width: 13px;
}

a {
    // To avoid underlines by default under anchor tag elements
    text-decoration: none;
}

// Styling for React Multi Carousel
// Move to a local file if this style is not needed for all of carousels
.lgksPe:hover:enabled,
.testimonial .lgksPe:focus:enabled {
    color: transparent !important;
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none;
}
// TODO: Move to Portfolio Component of Modern template as this does not belong here in a global
// stylesheet, for the application has multiple instances of .react-multi-carousel
.react-multi-carousel-dot--active button {
    background: #fe9a76 !important;
    background-color: #fe9a76 !important;
}

.react-multi-carousel-dot button {
    border-color: transparent !important;
    background-color: #c4c4c4;
    opacity: 0.6;
}


.react-multiple-carousel__arrow {
    padding: 20px 28px;
}

// For the razorpay iframe window that makes the page overflow
#logFrame {
    position: absolute;
}

@media only screen and (max-width: 768px) {
    hr {
        margin: 12px 0;
    }
}
