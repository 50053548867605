@import "@Static/css/common";

.roundedCornersButton {
    color: #fff;
    @include colorCombinationXYZ(
        (
            "bgc": (
                "key": 1,
            ),
        )
    );

    border-radius: 6px;
    padding: 10px 27px;
    transition: 0.2s ease all;
    &:not(.disabledButton):hover {
        @include colorCombinationXYZ(
            (
                "bgc": (
                    "key": 1,
                    "opacity": 0.85,
                ),
            )
        );
    }
}

.pillButton {
    color: #fff;
    @include colorCombinationXYZ(
        (
            "bgc": (
                "key": 1,
                color: (
                    0,
                    0,
                    0,
                ),
            ),
        )
    );
    border-radius: 1000000rem;
    padding: 3px 11px;
}

.plainTextButton {
    padding: 0 10px;
    background-color: transparent;
    text-decoration: underline;
    @include colorCombinationXYZ(
        (
            "tc": (
                "key": 1,
                color: (
                    0,
                    0,
                    0,
                ),
            ),
        )
    );
    &:hover {
        text-decoration: none;
    }
}

.button {
    cursor: pointer;
    width: fit-content;
    user-select: none;
    font-weight: 600;
    letter-spacing: normal;
    word-break: break-word;
}

.disabledButton {
    cursor: not-allowed;
    opacity: 0.7;
    filter: grayscale(0.5);
    pointer-events: none;
}
