.container {
    background-color: white;
}

.popupSection {
    padding: 0.8rem 1rem;
}

.header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #dfe1e5;
}

.plainButton {
    text-decoration: none;
    color: #2e384d;
}

.closeButton {
    padding: 0;
    display: flex;
    align-items: center;
}

.shareButtonsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
}

.shareButton {
    width: 5rem;
    margin: 0.5rem 0;
}

.boldText {
    font-weight: 700;
}

.shareUrlContainer {
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
}

.shareUrl {
    border: 1px solid #e4e4e4;
    border-radius: 6px;
    width: fit-content;
    padding: 0.3rem 1rem;
    cursor: pointer;
    word-break: break-all;
    margin: 0 1rem 1rem 0;
    flex-grow: 1;
}

@media only screen and (min-width: 601px) {
    .container {
        max-width: 28rem;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        width: 100%;
    }

    .popupSection:last-of-type {
        padding: 0.8rem 1rem 0 1rem;
    }
}
