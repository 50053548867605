.hostLogoImage {
    max-height: 40px;
}

// TODO: @aakarsh: is this needed?
// @media only screen and (max-width: 426px) {
//     .hostLogoImage {
//         max-height: 32px;
//     }
// }
